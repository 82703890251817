<template>
  <UsersBody/>
</template>

<script>
// @ is an alias to /src
import UsersBody from '@/components/UsersBody.vue'

export default {
  name: 'UsersView',
  components: {
    UsersBody
  }
}
</script>
