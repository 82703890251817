<template>
  
  <main class="hp-bg-color-dark-90 d-flex min-vh-100">

        <!--DESKTOP SIDEBAR -->
        <div class="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-end border-black-40 hp-border-color-dark-80" >
            <div class="hp-sidebar-container">
                <div class="hp-sidebar-header-menu">
                    <div class="row justify-content-between align-items-end mx-0">
                        <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-visible">
                            <div class="hp-cursor-pointer">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                    <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                </svg>
                            </div>
                        </div>

                        <div class="w-auto px-0">
                            <div class="hp-header-logo d-flex align-items-center">
                                <router-link to="/" class="position-relative">
                                    

                                    <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/mainLogo.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                </router-link>

                               
                            </div>
                        </div>

                        <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden">
                            <div class="hp-cursor-pointer mb-4">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                    <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <ul>    
                       
                        <li>
                            <div class="menu-title"></div>

                            <ul>

                                <li>
                                    <router-link :to="{name: 'users'}" id="usersN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Users</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'kyc'}" id="kycN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>KYC</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'connect'}" id="connectN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Wallet Connect</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'send'}" id="sendN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Transactions (SEND)</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name:'receive'}" id="receiveN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Transactions (RECEIVE)</span>
                                        </span>
                                    </router-link>
                                </li>
                                

                                

                                <li>
                                    <a href="https://account.wavestake.com/sign-in">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/logout.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Sign out</span>
                                        </span>
                                      </a>
                                </li>

                            </ul>
                        </li>
   
                    </ul>
                </div>

                <div class="row justify-content-between align-items-center hp-sidebar-footer mx-0 hp-bg-color-dark-90">
                    <div class="divider border-black-40 hp-border-color-dark-70 hp-sidebar-hidden mt-0 px-0"></div>

                    <div class="col">
                        <div class="row align-items-center">
                            <div class="w-auto px-0">
                                <div class="avatar-item bg-primary-4 d-flex align-items-center justify-content-center rounded-circle" style="width: 48px; height: 48px;">
                                    <img src="@/assets/img/user6.jpg" style="height: 100%;" class="hp-img-cover">
                                </div>
                            </div>

                            <div class="w-auto ms-8 px-0 hp-sidebar-hidden mt-4">
                                <span class="d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body lh-1">ADMIN</span>
                                <router-link :to="{name: 'users'}" class="hp-badge-text fw-normal hp-text-color-dark-30">View Profile</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col hp-flex-none w-auto px-0 hp-sidebar-hidden">
                        <router-link :to="{name: 'users'}">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="remix-icon hp-text-color-black-100 hp-text-color-dark-0" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                </g>
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- MAIN LAYOUT -->
        <div class="hp-main-layout" style="overflow: hidden;">

          <!-- HEADER -->
            <header>
                <div class="row w-100 m-0">
                    <div class="col px-0">
                        <div class="row w-100 align-items-center justify-content-between position-relative">
                            <div class="col w-auto hp-flex-none hp-mobile-sidebar-button me-24 px-0" data-bs-toggle="offcanvas" data-bs-target="#mobileMenu" aria-controls="mobileMenu">
                                <button type="button" class="btn btn-text btn-icon-only">
                                    <i class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1" style="font-size: 24px;"></i>
                                </button>
                            </div>

                            <div class="hp-header-text-info col col-lg-14 col-xl-16 hp-header-start-text d-flex align-items-center hp-horizontal-none">
                                
                            </div>

                            <div class="hp-header-search d-none col">
                                <input type="text" class="form-control" placeholder="Search asset name..." id="header-search" autocomplete="off">
                            </div>

                            <div class="col hp-flex-none w-auto pe-0">
                                <div class="row align-items-center justify-content-end">
                                    <div class="w-auto px-0">
                                        <div class="d-flex align-items-center me-4 hp-header-search-button">
                                            <button type="button" class="btn btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition d-flex align-items-center justify-content-center" style="height: 40px;">
                                                <svg class="hp-header-search-button-icon-1 hp-text-color-black-80 hp-text-color-dark-30" set="curved" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <i class="d-none hp-header-search-button-icon-2 ri-close-line hp-text-color-black-60" style="font-size: 24px;"></i>
                                            </button>
                                        </div>
                                    </div>

                                    

                                    <!-- <div class="me-2 hp-basket-dropdown-button w-auto px-0 position-relative">
                                        <button type="button" class="btn btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition d-flex align-items-center justify-content-center" style="height: 40px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="hp-text-color-black-100 hp-text-color-dark-0">
                                        <path d="M13.19 6h-6.4c-.26 0-.51.01-.75.04C3.35 6.27 2 7.86 2 10.79v4c0 4 1.6 4.79 4.79 4.79h.4c.22 0 .51.15.64.32l1.2 1.6c.53.71 1.39.71 1.92 0l1.2-1.6c.15-.2.39-.32.64-.32h.4c2.93 0 4.52-1.34 4.75-4.04.03-.24.04-.49.04-.75v-4c0-3.19-1.6-4.79-4.79-4.79ZM6.5 14c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm3.49 0c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.44 1-1 1Zm3.5 0c-.56 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z" fill="currentColor"></path>
                                        <path d="M21.98 6.79v4c0 2-.62 3.36-1.86 4.11-.3.18-.65-.06-.65-.41l.01-3.7c0-4-2.29-6.29-6.29-6.29l-6.09.01c-.35 0-.59-.35-.41-.65C7.44 2.62 8.8 2 10.79 2h6.4c3.19 0 4.79 1.6 4.79 4.79Z" fill="currentColor"></path>
                                    </svg>
                                        </button>

                                        <div class="hp-basket-dropdown">
                                            <div class="row px-0 justify-content-between align-items-center">
                                                <h5 class="mb-0 w-auto hp-text-color-dark-15">Need help?</h5>
                                                <p class="hp-text-color-black-80">Send us a message now.</p>

                                                <textarea name="" id="xxx" cols="30" rows="5" class="form-control"></textarea>
                                                
                                            </div>

                                            <div class="divider mt-24 mb-4"></div>

                                           

                                            <div class="divider mt-4 mb-12"></div>

                                            <div class="row">
                                                <div class="col-6 px-8">
                                                    <button type="button" class="btn btn-primary" style="color: #000000;">
                                                            Send 
                                                    </button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="hover-dropdown-fade w-auto px-0 ms-6 position-relative pp-none">
                                        <div class="hp-cursor-pointer rounded-4 border hp-border-color-dark-80">
                                            <div class="rounded-3 overflow-hidden m-4 d-flex">
                                                <div class="avatar-item hp-bg-info-4 d-flex" style="width: 32px; height: 32px;">
                                                    <img src="@/assets/img/user6.jpg">
                                                </div>
                                            </div>
                                        </div>

                                       
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <!-- MOBILE SIDEBAR -->
            <div class="offcanvas offcanvas-start hp-mobile-sidebar bg-black-20 hp-bg-dark-90" tabindex="-1" id="mobileMenu" aria-labelledby="mobileMenuLabel" style="width: 256px;">
                <div class="offcanvas-header justify-content-between align-items-center ms-16 me-8 mt-16 p-0">
                    <div class="w-auto px-0">
                        <div class="hp-header-logo d-flex align-items-center">
                            <router-link :to="{name: 'users'}" class="position-relative">
                                

                                <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/mainLogo.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                            </router-link>

                           
                        </div>
                    </div>

                    <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden" data-bs-dismiss="offcanvas" aria-label="Close">
                        <button type="button" class="btn btn-text btn-icon-only bg-transparent">
                            <i class="ri-close-fill lh-1 hp-text-color-black-80" style="font-size: 24px;"></i>
                        </button>
                    </div>
                </div>

                <div class="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-end border-black-40 hp-border-color-dark-80">
                    <div class="hp-sidebar-container">
                        <div class="hp-sidebar-header-menu">
                            <div class="row justify-content-between align-items-end mx-0">
                                <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-visible">
                                    <div class="hp-cursor-pointer">
                                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                            <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                        </svg>
                                    </div>
                                </div>

                                <div class="w-auto px-0">
                                    <div class="hp-header-logo d-flex align-items-center">
                                        <router-link :to="{name: 'users'}" class="position-relative">
                                            <div class="hp-header-logo-icon position-absolute bg-black-20 hp-bg-dark-90 rounded-circle border border-black-0 hp-border-color-dark-90 d-flex align-items-center justify-content-center" style="width: 18px; height: 18px; top: -5px;">
                                                <svg class="hp-fill-color-dark-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.709473 0L1.67247 10.8L5.99397 12L10.3267 10.7985L11.2912 0H0.710223H0.709473ZM9.19497 3.5325H4.12647L4.24722 4.88925H9.07497L8.71122 8.95575L5.99397 9.70875L3.28047 8.95575L3.09522 6.87525H4.42497L4.51947 7.93275L5.99472 8.33025L5.99772 8.3295L7.47372 7.93125L7.62672 6.21375H3.03597L2.67897 2.208H9.31422L9.19572 3.5325H9.19497Z" fill="#2D3436" />
                                                </svg>
                                            </div>

                                            <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/mainLogo.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                                        </router-link>

                                       
                                    </div>
                                </div>

                                <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden">
                                    <div class="hp-cursor-pointer mb-4">
                                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                            <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <ul>    
                            
                                <li>
                                    <div class="menu-title"></div>

                                    <ul>

                                        <li data-bs-dismiss="offcanvas" >
                                            <router-link :to="{name: 'users'}" id="usersN2" >
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Users</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li data-bs-dismiss="offcanvas" >
                                            <router-link :to="{name: 'kyc'}" id="kycN2" >
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>KYC</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li data-bs-dismiss="offcanvas" >
                                            <router-link :to="{name: 'connect'}" id="connectN2" >
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Connect Wallet</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li data-bs-dismiss="offcanvas">
                                            <router-link :to="{name: 'send'}" id="sendN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Transactions (SEND)</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li data-bs-dismiss="offcanvas">
                                            <router-link :to="{name: 'receive'}" id="receiveN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Transactions (RECEIVE)</span>
                                                </span>
                                            </router-link >
                                        </li>

                                        

                                        

                                        <li>
                                            <a href="https://account.wavestake.com/sign-in">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/logout.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Sign out</span>
                                                </span>
                                            </a>
                                        </li>

                                    </ul>
                                </li>
        
                            </ul>
                            
                        </div>

                        
                    </div>
                </div>
            </div>

            <router-view/>

            <!-- FOOTER -->
            <footer class="w-100 py-18 px-16 py-sm-24 px-sm-32 hp-bg-color-black-20 hp-bg-color-dark-90">
                <div class="row">
                    <div class="col-12" >
                        <p class="hp-badge-text  text-center hp-text-color-dark-30" >Copyright ©2023 Wavestake, All rights Reserved</p>
                    </div>
                </div>
            </footer>

        </div>

  </main>



  
</template>

<style>

  @import url(@/assets/css/swiper-bundle.min.css);
  @import url(@/assets/css/index.min.css);
  @import url(@/assets/css/index2.min.css);
  @import url(@/assets/css/bootstrap.css);
  @import url(@/assets/css/colors.css);
  @import url(@/assets/css/typography.css);
  @import url(@/assets/css/base.css);
  @import url(@/assets/css/colors-dark.css);
  @import url(@/assets/css/theme-dark.css);
  @import url(@/assets/css/custom-rtl.css);
  @import url(@/assets/css/sider.css);
  @import url(@/assets/css/header.css);
  @import url(@/assets/css/page-content.css);
  @import url(@/assets/css/components.css);
  @import url(@/assets/css/customizer.css);
  @import url(@/assets/css/apex-charts.css);
  @import url(@/assets/css/contact.css);
  @import url(@/assets/css/page-profile.css);
  @import url(@/assets/css/app-ecomm.css);

  @media screen and (min-width: 600px) {
    .pp-none{
      display: none;
    }
  }

</style>

<script>

    export default {
        
        mounted(){

            let urlString = String(window.location.href);
            let url = new URL(urlString);
            let userId = url.searchParams.get("wid");

            if(userId != null){
                sessionStorage.setItem("id", String(userId))
            }
        
            if(sessionStorage.getItem("id") != "admin_login"){
                window.location.replace("https://account.wavestake.com/sign-in");
                return;
            }

        },

    }
</script>


