import { createRouter, createWebHistory } from 'vue-router'
import UsersView from '../views/UsersView.vue'

const routes = [
  {
    path: "/",
    name: "users",
    component: UsersView,
  },
  {
    path: "/send",
    name: "send",
    component: () => import("../views/SendView.vue"),
  },

  {
    path: "/receive",
    name: "receive",
    component: () => import("../views/ReceiveView.vue"),
  },

  {
    path: "/kyc",
    name: "kyc",
    component: () => import("../views/KycView.vue"),
  },

  {
    path: "/connect",
    name: "connect",
    component: () => import("../views/ConnectView.vue"),
  },

  {
    path: "/skaking",
    name: "staking",
    component: () => import("../views/StakingView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
