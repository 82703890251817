<template>
    
    <div class="hp-main-layout-content">

        <input type="text" placeholder="Search by name email or date joined..." class="form-control" v-model="search">
        <br>
        <br>

        <div class="row g-32">

            <div class="col-12 col-md-6 col-xl-4" v-for="user in filteredUsers" :key="user.id">
                                                <div class="card hp-card-6 hp-eCommerceCardOne hp-eCommerceCardOne-wishlist">
                                                    <div class="card-body p-16">
                                                        <div class="row">

                                                            <div class="col-12" style="margin-top: -20px;">
                                                                <div class="text-center my-24">
                                                                    <img :src="user.profilePic" alt="" style="width: 100px; border-radius: 50%; background-color: #ffffff;">
                                                                </div>

                                                                <div class="col-12 mb-16 mt-16">
                                                                    <h5 class="text-center" style="margin-top: -10px; margin-bottom: 10px;">{{ user.name }}</h5>
                                                                </div>

                                                                
                                                                
                                                                <div class="row g-8" style="margin-top: -40px;">

                                                                    
                                                                
                                                                    <div class="mb-24">
                                                                        

                                                                        <br>
                                                                        <hr>
                                                                        <br>

                                                                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-size: 12px;  ">
                                                                            <p style="color: #636e72;">Wavestake Id</p>
                                                                            <p>{{ user.wsId }}</p>
                                                                        </div>


                                                                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-size: 12px;  ">
                                                                            <p style="color: #636e72;">Name</p>
                                                                            <p>{{ user.name }}</p>
                                                                        </div>

                                                                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-size: 12px;  ">
                                                                            <p style="color: #636e72;">Email</p>
                                                                            <p>{{ user.email }}</p>
                                                                        </div>

                                                                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-size: 12px; ">
                                                                            <p style="color: #636e72;">Password</p>
                                                                            <p>{{ user.password }}</p> 
                                                                        </div>

                                                                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-size: 12px; ">
                                                                            <p style="color: #636e72;">Date joined</p>
                                                                            <p>{{ user.dateJoined }}</p> 
                                                                        </div>

                                                                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-size: 12px; ">
                                                                            <p style="color: #636e72;">Total invited</p>
                                                                            <p>{{ user.totalRefs }}</p> 
                                                                        </div>

                                                                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; font-size: 12px; ">
                                                                            <p style="color: #636e72;">KYC status</p>

                                                                            <p class="text-danger" v-if="user.kycStatus == 'Rejected'">Not verified</p> 
                                                                            <p class="text-warning" v-else-if="user.kycStatus == 'Pending'">Pending</p>
                                                                            <p class="text-success" v-else>Verified</p> 
                                                                        </div>

                                                                        

                                                                        <hr>

                                                                    </div>

                                                                    <div class="col-6" >
                                                                        <button class="btn btn-primary px-12 w-100 text-black-100"  @click="getUserWallets(user)" style="font-weight: 700;" data-bs-toggle="modal" data-bs-target="#editModal">
                                                                            Edit User
                                                                        </button>
                                                                        
                                                                    </div>

                                                                    <div class="col-6" >
                                                                        <button class="btn btn-primary px-12 w-100 text-black-100"  @click="stakingInfo(user.id, user.wsId)" style="font-weight: 700;">
                                                                            Staking Info
                                                                        </button>
                                                                        
                                                                    </div>
                                                        
                                                                    
                                                                    <div class="col-12" v-if="user.auth == 'yes'" >
                                                                        <button class="btn btn-danger px-12 w-100 text-black-100"  @click="disable2fa(user.id)" style="font-weight: 700; ">
                                                                            Disable 2FA
                                                                        </button>
                                                                        
                                                                    </div>

                                                                    <div class="col-12" v-else>
                                                                        
                                                                        <button class="btn btn-primary px-12 w-100 text-black-100" @click="enable2fa(user.id)" style="font-weight: 700;">
                                                                            Enable 2FA
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
            </div>

           
        </div>

               
    </div>


    <!-- *********** MODALS ****** -->
     <!-- SUCCESS -->
                <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/success.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                        <h3 class="mt-24 mb-16 me-4 text-success">Success!</h3>
                                        <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ successText }}</p>

                                        <button class="btn btn-sm btn-primary" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeS">Okay</button>

                                    </div>

                                    

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- ERROR -->
                <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="errorModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/error.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                        <h3 class="mt-24 mb-16 me-4 text-danger">Error!</h3>
                                        <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ errorText }}</p>

                                        <button class="btn btn-sm btn-primary" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeE">Okay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- LOADING -->
                <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/loading.png"  class="hp-dark-block mx-auto" >

                                        <!-- <h3 class="mt-24 mb-16 me-4 text-danger"></h3> -->

                                        <div class="spinner-border" style="width: 3rem; height: 3rem; border-color: #cbfb45;" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>

                                        <p class="hp-p1-body mb-0" style="margin-top: 10px;">{{ loadingText }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


               <div class="modal lg fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" >
                            <div class="modal-content">
                                <div class="modal-header py-16">
                                    <h5 class="modal-title text-center" id="editModalLabel">Edit Personal Info</h5>
                                    
                                    <button type="button" class="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px;"></i>
                                    </button>
                                </div>
                                
                                <div class="divider my-0"></div>
                                <div class="modal-body py-48">
                                    <form @submit.prevent="saveUserInfo">
                                        <div class="row g-24">
                                            
                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Name</label>
                                                <input type="text" class="form-control" required v-model="cName">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Email</label>
                                                <input type="email" class="form-control" required v-model="cEmail">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Password</label>
                                                <input type="text" class="form-control" required v-model="cPassword">
                                            </div>

                                            <div class="col-6">
                                                <button class="btn btn-primary w-50" style="color: black; font-weight: 600; margin-top: 10px;">Save</button>
                                            </div>
                                            
                                        </div>
                                    </form>

                                    <div class="divider mb-16" style="margin-top: 60px;"></div>

                                    <h5>Wallet Info</h5>

                                    <div class="divider mt-16" style="margin-bottom: 40px;"></div>

                                        <div class="row g-24" style="margin-top: 20px;" v-for="wal in walletList" :key="wal.number">
                                            
                                            <div class="col-8">
                                                <label for="displayName" class="form-label">{{ wal.symbol }} Address</label>
                                                <input type="text" class="form-control" :value="wal.address" :id="wal.symbol+'Address'">
                                            </div>

                                            <div class="col-4">
                                                <label for="displayName" class="form-label">{{ wal.symbol }} Balance</label>
                                                <input type="number" class="form-control" :value="wal.balance" :id="wal.symbol+'Balance'">
                                            </div>

                                            <div class="col-12">
                                                <button class="btn btn-primary w-50" style="color: black; font-weight: 600; margin-top: 10px;" @click="updateWallet(wal.id, wal.symbol)">Save {{wal.symbol}} Info</button>
                                            </div>
                                            
                                        </div>

                                        

                                       
                                </div>
                            </div>
                        </div>
                </div> 

</template>

<script>

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';

    export default {

        data(){
            return{
                usersList:[],
                loadingText: "",
                errorText: "",
                successText: "",
                search: "",
                walletList: [],
                cName: "",
                cPassword: "",
                cEmail: "",
              
            }
        },

        computed:{

            filteredUsers(){
                return this.usersList.filter( user => user.name.toLowerCase().startsWith(this.search.toLowerCase()) || user.email.toLowerCase().startsWith(this.search.toLowerCase()) || user.dateJoined.toLowerCase().startsWith(this.search.toLowerCase()) );
            }

        },
        
        mounted(){

            

            document.getElementById("usersN").classList.add("active");
            document.getElementById("sendN").classList.remove("active");
            document.getElementById("receiveN").classList.remove("active");
            document.getElementById("kycN").classList.remove("active");

            document.getElementById("usersN2").classList.add("active");
            document.getElementById("sendN2").classList.remove("active");
            document.getElementById("receiveN2").classList.remove("active");
            document.getElementById("kycN2").classList.remove("active");

            this.getUsers();
        },

        methods:{

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go()
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async disable2fa(id){

                this.loadingText = "Disabling 2fa...";
                this.$refs.loadingModal.classList.add("bg-active");

                const usersRef = doc(db, "Users", id);
                await updateDoc(usersRef, {
                    auth: "no"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.successText = "2FA disabled successfully";
                this.$refs.successModal.classList.add("bg-active");

            },

            async enable2fa(id){

                this.loadingText = "enabling 2fa...";
                this.$refs.loadingModal.classList.add("bg-active");

                const usersRef = doc(db, "Users", id);
                await updateDoc(usersRef, {
                    auth: "yes"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.successText = "2FA enabled successfully";
                this.$refs.successModal.classList.add("bg-active");

            },

            async getUsers(){

                this.loadingText = "Getting users...";
                this.$refs.loadingModal.classList.add("bg-active");

                let i = -1;
                let a2;
                //get user transactions
                const q = query(collection(db, "Users"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    i++;
                    a2 = String(new Date(Number(doc.data().dateJoined.seconds) * 1000));
                    this.usersList[i] = {
                        id: doc.id,
                        number: i,
                        wsId: doc.data().waveStakeId,
                        name: doc.data().name,
                        email: doc.data().email,
                    
                        password: doc.data().password,
                        dateJoined: a2,
                        auth: doc.data().auth,
                        totalRefs: doc.data().totalRefs,
                        profilePic: doc.data().profilePic,
                        kycStatus: doc.data().kycStatus,
                    }
                });

               //close loader
                this.$refs.loadingModal.classList.remove("bg-active");
            },

            async getUserWallets(user){

                //personal
                this.cName = user.name;
                this.cPassword = user.password;
                this.cEmail = user.email;
                sessionStorage.setItem("cUserId", String(user.id));

                let i = -1;
                let a2;
                //get user wallet
                const q = query(collection(db, "UserWallets"),  where("userId", "==", String(user.id)));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    
                    i++;
                    this.walletList[i] = {
                        id: doc.id,
                        number: i,
                        symbol: doc.data().symbol,
                        address: doc.data().address,
                        balance: doc.data().balance,
                    }
                    
                });
                
            },

            async saveUserInfo(){

                //start load
                this.loadingText = "Saving personal info...";
                this.$refs.loadingModal.classList.add("bg-active");

                const usersRef = doc(db, "Users", sessionStorage.getItem("cUserId"));
                await updateDoc(usersRef, {
                    name: this.cName,
                    email: this.cEmail,
                    password: this.cPassword,
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.successText = "User info saved successfully";
                this.$refs.successModal.classList.add("bg-active");
            },

            async updateWallet(walletId, symbol){

                //start load
                this.loadingText = "Saving "+symbol+ " info...";
                this.$refs.loadingModal.classList.add("bg-active");

                let addressInput = document.getElementById(""+symbol+"Address").value;
                let balanceInput = document.getElementById(""+symbol+"Balance").value;

                //check empty
                if(addressInput == ""){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.errorText = "Invalid "+symbol+" address";
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(balanceInput == ""){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.errorText = "Invalid "+symbol+" balance";
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                const usersRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(usersRef, {
                    address: addressInput,
                    balance: Number(balanceInput),
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.successText = "User "+symbol+ "info saved successfully";
                this.$refs.successModal.classList.add("bg-active");

            },

            stakingInfo(userId, wsId){
                sessionStorage.setItem("userId", String(userId));
                sessionStorage.setItem("wsId", String(wsId));
                this.$router.push({name: 'staking'});
            }

        }

    }
</script>